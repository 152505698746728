export default {
  blogerName: 'СASINOCLUB',
  socialsList: [
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@cassinoclub2510',
    }
  ],
  projects: [
    {
      name: 'starda',
      url: 'https://stardacasino.life/cde19cc30',
      gameTitle: 'Starda Queen (BGaming)',
    },
    {
      name: 'drip',
      url: 'https://stardacasino.life/cde19cc30',
      gameTitle: 'Alien Fruits (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://legzocasino.life/c7526244fJET',
      gameTitle: 'Legzo Punk (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://solcasino.life/c613a8164',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzicasino.life/c008a5350',
      gameTitle: 'Izzi Art (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://freshcasino.life/c6bf630cd',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jetcasino.life/c62d752ab',
      gameTitle: 'Candy Monsta (BGaming)',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>СASINOCLUB</strong> e receba 125 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'СASINOCLUB',
  casinoBonus1: '<strong>100% + 500FS</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>125FS</strong><div>Para cadastro com código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  bonusesText: 'GIROS GRATIS',
  copyMessage: 'Código promocional copiado',
  theme: 'dark'
}
